import React from "react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Page from "../../../components/Page/Page";
import DentaCuretoothpaste from "./PopupComponent/DentaCuretoothpaste";
import DentaCureMouthWash from "./PopupComponent/DentaCureMouthWash";
import DentaCureintensivegel from "./PopupComponent/DentaCureintensivegel";
import DentalFloss from "./PopupComponent/DnetalFloss";
import Allkit from "./PopupComponent/Allkit";
import { useTranslation } from "react-i18next";

const Shop = () => {
  const currentUrl = window.location.href;
  const lang = currentUrl.split("/").pop();
  const { t } = useTranslation();

  return (
    <Page>
      <section className="landingPage">
        <section className="landingPage__smartInsurance">
          <div className="landingPage__healthyTeeth__container">
            <h1
              style={{
                color: "#5c8894",
                textTransform: "none",
              }}
            >
              {t("Shop.Main_heading_part1")}
              <br />
              {t("Shop.Main_heading_part2")}
            </h1>
          </div>
          <div className="landingPage__smartInsurance__container">
            <div className="landingPage__smartInsurance__container__details">
              <div className="Shop__mainpage">
                <p>
                  {t("Shop.Sub_heading_01")}
                </p>
                <ul
                  style={{
                    margin: "0px 10px 10px 10px",
                    marginLeft: "30px",
                    fontWeight: "normal",
                    fontSize: "16px",
                    fontWeight: 400,
                  }}
                >
                  <li style={{ fontSize: "16px", fontWeight: 400 }}>
                    {t("Shop.Para_No_1")}
                  </li>
                  <li style={{ fontSize: "16px", fontWeight: 400 }}>
                    {t("Shop.Para_No_2")}
                  </li>
                  <li style={{ fontSize: "16px", fontWeight: 400 }}>{t("Shop.Para_No_3")}</li>
                  <li style={{ fontSize: "16px", fontWeight: 400 }}>
                    {t("Shop.Para_No_4")}
                  </li>
                  <li style={{ fontSize: "16px", fontWeight: 400 }}>
                    {t("Shop.Para_No_5")}
                  </li>
                </ul>
                <p>
                  {t("Shop.Sub_heading_02")}
                </p>
              </div>
            </div>
            <div
              className="landingPage__smartInsurance__container__picture"
              id="shop_image"
            >  

            {lang === "pl" ? (
              <img
                src={require("../../../assets/Shop_PL desktop.png")}
                alt=""
              />
            ) : (
              <img
                src={require("../../../assets/Shop Home 09.52.49.png")}
                alt=""
              />
            )}
            </div>
          </div>
        </section>
      </section>

      {/* No # 00 */}

      <section className="landingPage__howItwork">
        <div className="landingPage__howItwork__container">
          <div
            className="landingPage__howItwork__container__picture"
            id="Product_Pics"
            style={{ minWidth: "50%" }}
          >
            <img
              style={{ height: "60%", width: "60%" }}
              src={require("../../../assets/no 1.png")}
              alt=""
            />
          </div>

          <div
            className="landingPage__howItwork__container__details"
            style={{ minWidth: "50%" }}
            id="Product_detail"
          >
            <h3
              className="landingPage__howItwork__container__details__heading"
              style={{
                fontSize: "35px",
                marginBottom: "10px",
                fontWeight: 500,
                display: "inline-flex",
              }}
            >
                {t("Shop.Product_1_heading")} <DentaCuretoothpaste />
            </h3>
            <p className="Shop__mainpage" style={{ fontWeight: "normal" }}>
              <ul
                style={{
                  margin: "0px 10px 10px 10px",
                  marginLeft: "30px",
                  fontSize: "16px",
                  fontWeight: 400,
                }}
              >
                <li>{t("Shop.Product_1_point1")}</li>
                <li>{t("Shop.Product_1_point2")}</li>
                <li>{t("Shop.Product_1_point3")}</li>
                <li>{t("Shop.Product_1_point4")}</li>
                <li>{t("Shop.Product_1_point5")}</li>
                <li>{t("Shop.Product_1_point6")}</li>
                <li>{t("Shop.Product_1_point7")}</li>
                <li>{t("Shop.Product_1_point8")}</li>
              </ul>
              <div className="landingPage__allCare__container__details__imageContainer">
                <div className="Shop__price">
                  <h1>{t("Shop.currency")} 0.00</h1>
                </div>
                <div className="Shop__Button">
                  <ShoppingCartIcon
                    style={{
                      marginLeft: "5px",
                      fontSize: "30px",
                      cursor: "pointer",
                    }}
                  />
                  <span style={{ padding: "10px", cursor: "pointer" }}>
                    {" "}
                    {t("Shop.coming_soon")}
                  </span>
                </div>
              </div>
            </p>
          </div>
        </div>
      </section>

      {/* No # 01 */}

      <section className="landingPage__howItwork">
        <div className="landingPage__howItwork__container">
          <div
            className="landingPage__howItwork__container__picture"
            style={{ minWidth: "50%" }}
            id="Product_Pics"
          >
            <img
              style={{ height: "35%", width: "33%" }}
              src={require("../../../assets/Mouth wash.png")}
              alt=""
            />
          </div>

          <div
            className="landingPage__howItwork__container__details"
            style={{ minWidth: "50%" }}
            id="Product_detail"
          >
            <h3
              className="landingPage__howItwork__container__details__heading"
              style={{
                fontSize: "35px",
                marginBottom: "10px",
                fontWeight: 500,
                display: "inline-flex",
              }}
            >
              {t("Shop.Product_2_heading")}
              {/* <AddCircleIcon
                style={{
                  color: "#dd3333",
                  fontSize: "55px",
                  cursor: "pointer",
                }}
              /> */}
              <DentaCureMouthWash />
            </h3>
            <p className="Shop__mainpage" style={{ fontWeight: "normal" }}>
              <ul
                style={{
                  margin: "0px 10px 10px 10px",
                  marginLeft: "30px",
                  fontSize: "16px",
                  fontWeight: 400,
                }}
              >
                <li>{t("Shop.Product_2_point1")}</li>
                <li>{t("Shop.Product_2_point2")}</li>
                <li>{t("Shop.Product_2_point3")}</li>
                <li>{t("Shop.Product_2_point4")}</li>
                <li>{t("Shop.Product_2_point5")}</li>
                <li>{t("Shop.Product_2_point6")}</li>
                <li>{t("Shop.Product_2_point7")}</li>
              </ul>
              <div className="landingPage__allCare__container__details__imageContainer">
                <div className="Shop__price">
                  <h1>{t("Shop.currency")} 0.00</h1>
                </div>
                <div className="Shop__Button">
                  <ShoppingCartIcon
                    style={{
                      marginLeft: "5px",
                      fontSize: "30px",
                      cursor: "pointer",
                    }}
                  />
                  <span style={{ padding: "10px", cursor: "pointer" }}>
                    {" "}
                    {t("Shop.coming_soon")}
                  </span>
                </div>
              </div>
            </p>
          </div>
        </div>
      </section>

      {/* No # 02 */}

      <section className="landingPage__howItwork">
        <div className="landingPage__howItwork__container">
          <div
            className="landingPage__howItwork__container__picture"
            id="Product_Pics"
            style={{ minWidth: "50%" }}
          >
            <img
              style={{ height: "50%", width: "50%" }}
              src={require("../../../assets/No 2.png")}
              alt=""
            />
          </div>

          <div
            className="landingPage__howItwork__container__details"
            style={{ minWidth: "50%" }}
            id="Product_detail"
          >
            <h3
              className="landingPage__howItwork__container__details__heading"
              style={{
                fontSize: "35px",
                marginBottom: "10px",
                fontWeight: 500,
                display: "inline-flex",
              }}
            >
              {t("Shop.Product_3_heading")}
              {/* <AddCircleIcon
                style={{
                  color: "#dd3333",
                  fontSize: "55px",
                  cursor: "pointer",
                }}
              /> */}
              <DentaCureintensivegel />
            </h3>
            <p className="Shop__mainpage" style={{ fontWeight: "normal" }}>
              <ul
                style={{
                  margin: "0px 10px 10px 10px",
                  marginLeft: "30px",
                  fontSize: "16px",
                  fontWeight: 400,
                }}
              >
                <li>{t("Shop.Product_3_point1")}</li>
                <li>{t("Shop.Product_3_point2")}</li>
                <li>{t("Shop.Product_3_point3")}</li>
                <li>{t("Shop.Product_3_point4")}</li>
                <li>{t("Shop.Product_3_point5")}</li>
                <li>{t("Shop.Product_3_point6")}</li>
                <li>{t("Shop.Product_3_point7")}</li>
              </ul>
              <div className="landingPage__allCare__container__details__imageContainer">
                <div className="Shop__price">
                  <h1>{t("Shop.currency")} 0.00</h1>
                </div>
                <div className="Shop__Button">
                  <ShoppingCartIcon
                    style={{
                      marginLeft: "5px",
                      fontSize: "30px",
                      cursor: "pointer",
                    }}
                  />
                  <span style={{ padding: "10px", cursor: "pointer" }}>
                    {" "}
                    {t("Shop.coming_soon")}
                  </span>
                </div>
              </div>
            </p>
          </div>
        </div>
      </section>

      {/* No # 03 */}

      <section className="landingPage__howItwork">
        <div className="landingPage__howItwork__container">
          <div
            className="landingPage__howItwork__container__picture"
            id="Product_Pics"
            style={{ minWidth: "50%" }}
          >
            <img
              style={{ height: "50%", width: "50%" }}
              src={require("../../../assets/Floss.png")}
              alt=""
            />
          </div>

          <div
            className="landingPage__howItwork__container__details"
            style={{ minWidth: "50%" }}
            id="Product_detail"
          >
            <h3
              className="landingPage__howItwork__container__details__heading"
              style={{
                fontSize: "35px",
                marginBottom: "10px",
                fontWeight: 500,
                display: "inline-flex",
              }}
            >
              {t("Shop.Product_4_heading")}
              {/* <AddCircleIcon
                style={{
                  color: "#dd3333",
                  fontSize: "55px",
                  cursor: "pointer",
                }}
              /> */}
              <DentalFloss />
            </h3>
            <p className="Shop__mainpage" style={{ fontWeight: "normal" }}>
              {" "}
              <ul
                style={{
                  margin: "0px 10px 10px 10px",
                  marginLeft: "30px",
                  fontSize: "16px",
                  fontWeight: 400,
                }}
              >
                <li>{t("Shop.Product_4_point1")}</li>
                <li>{t("Shop.Product_4_point2")}</li>
                <li>{t("Shop.Product_4_point3")}</li>
                <li>{t("Shop.Product_4_point4")}</li>

              </ul>
              <div className="landingPage__allCare__container__details__imageContainer">
                <div className="Shop__price">
                  <h1>{t("Shop.currency")} 0.00</h1>
                </div>
                <div className="Shop__Button">
                  <ShoppingCartIcon
                    style={{
                      marginLeft: "5px",
                      fontSize: "30px",
                      cursor: "pointer",
                    }}
                  />
                  <span style={{ padding: "10px", cursor: "pointer" }}>
                    {" "}
                    {t("Shop.coming_soon")}
                  </span>
                </div>
              </div>
            </p>
          </div>
        </div>
      </section>

      {/* No # 04 */}

      <section className="landingPage__howItwork">
        <div className="landingPage__howItwork__container">
          <div
            className="landingPage__howItwork__container__picture"
            id="Product_Pics"
            style={{ minWidth: "50%" }}
          >
            <img
              style={{ height: "60%", width: "60%" }}
              src={require("../../../assets/DentaCure all products.png")}
              alt=""
            />
          </div>

          <div
            className="landingPage__howItwork__container__details"
            style={{ minWidth: "50%" }}
            id="Product_detail"
          >
            <h3
              className="landingPage__howItwork__container__details__heading"
              style={{
                fontSize: "35px",
                marginBottom: "10px",
                fontWeight: 500,
                display: "inline-flex",
              }}
            >
              {t("Shop.Product_5_heading")}
              {/* <AddCircleIcon
                style={{
                  color: "#dd3333",
                  fontSize: "55px",
                  cursor: "pointer",
                }}
              /> */}
              <Allkit />
            </h3>
            <p className="Shop__mainpage" style={{ fontWeight: "normal" }}>
              {" "}
              <ul
                style={{
                  margin: "0px 10px 10px 10px",
                  marginLeft: "30px",
                  fontSize: "16px",
                  fontWeight: 400,
                }}
              >
                <li>
                  {t("Shop.Product_5_point1")}
                </li>
              </ul>
              <div className="landingPage__allCare__container__details__imageContainer">
                <div className="Shop__price">
                  <h1>{t("Shop.currency")} 0.00</h1>
                </div>
                <div className="Shop__Button">
                  <ShoppingCartIcon
                    style={{
                      marginLeft: "5px",
                      fontSize: "30px",
                      cursor: "pointer",
                    }}
                  />
                  <span style={{ padding: "10px", cursor: "pointer" }}>
                    {" "}
                    {t("Shop.coming_soon")}
                  </span>
                </div>
              </div>
            </p>
          </div>
        </div>
      </section>
    </Page>
  );
};

export default Shop;
