import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Page from '../../../components/Page/Page';
import AlertMessage from "../../../components/SnackbarMessages/AlertMessage";
import SuccessMessage from '../../../components/SnackbarMessages/SuccessMessage';
import useClientInsurance from '../../../hooks/useClientInsurance';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import MemberInfo from './Components/MemberInfo';
import PaymentInfo from './Components/PaymentInfo';
import { Link, useParams } from "react-router-dom";


const CoInsuredMemberDetail = () => {
  const { id } = useParams();
  const [clientInfo, setClientInfo] = useState();
  const [ errorMessage, setErrorMessage ] = useState('');
  const [successMessage, setSuccessMessage] = useState();
  const { getCoInsuredMmeberInfoByIdAdmin } = useClientInsurance();

  const getCoInsuredMember = async () => {
    const response = await getCoInsuredMmeberInfoByIdAdmin(id);
      console.log("dddddddddddddd")
      console.log(response)


    if (response?.status < 300) {
      setClientInfo(response?.coinsured_client_info);
    } else if (response?.status > 300) {
      setErrorMessage(response?.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
    }
  }
  useEffect(() => {
    getCoInsuredMember();
  }, []);

  



  return (
    <Page>
      <section className='insuredClientGroup'>
        <header className='insuredClientGroup__header'>
            <BusinessCenterOutlinedIcon className='insuredClientGroup__header__icon' />
            <p>Co Insured Person - {clientInfo?.first_name} {clientInfo?.last_name} </p>
          </header>
        <div className='insuredClientGroup__container'>
          <div className='insuredClientGroup__container__cardsBox'>
            <div className='insuredClientGroup__container__cardsBox__left'>
              <MemberInfo policyInfo={clientInfo} />
            </div>
            <div className='insuredClientGroup__container__cardsBox__right'>
              <PaymentInfo policyInfo={clientInfo} />
            </div>
          </div>
        </div>
      </section>
    </Page>
  )
}

export default CoInsuredMemberDetail
