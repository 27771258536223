import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../config/helpers/i18n";
import Page from "../../../components/Page/Page";
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import useCavitasDocs from '../../../hooks/useCavitasDocs';
import AlertMessage from '../../../components/SnackbarMessages/AlertMessage';
import FOEcavitasPL from "../../../Szablon-pliku-Lista-pracowników.xlsx";
import FOEcavitasEN from "../../../Fiie-template-list-of-employees.xlsx";

import TCcavitasPL from "../../../Warunki-Cavitas-HAM-12023.pdf";
import TCcavitasEN from "../../../TC-Cavitas-HAM-12023.pdf";


import IPIDcavitasEN from "../../../IPID-Cavitas-HAM-12023.pdf";
import IPIDcavitasPL from "../../../DIPU-Cavitas-HAM-12023.pdf";


import MICcavitasPL from "../../../Ramowa-Umowa-Cavitas-HAM-12023.pdf";
import MICcavitasEN from "../../../Master-Ins-Contr-Cavitas-HAM-12023.pdf";

import PolicycavitasPL from "../../../Program-Policy-Cavitas-HAM-12023.pdf";
import PolicycavitasEN from "../../../Policy-schedule-Cavitas-HAM-12023.pdf";

import BrochurecavitasPL from "../../../Broszura-Cavitas.pdf";
import BrochurecavitasEN from "../../../Brochure-Cavitas.pdf";


const CavitasDocsView = () => {
  const currentUrl = window.location.href;
  const lang = currentUrl.split("/").pop();
  const { t } = useTranslation();

  const {getCavitasDocs} = useCavitasDocs();
  const [cavitasDocs, setCavitasDocs] = useState([]);
  const [errorMessage, setErrorMessage] = useState();

  const getAllCavitasDocs = async () => {

      const response = await getCavitasDocs();

      if (response?.status < 300) {
        setCavitasDocs(response?.cavitas_documents)
      } else if (response?.status > 300) {
        setErrorMessage(response.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 5000);
      }
  }

  useEffect(() => {
    const currentUrl = window.location.href;
    let lang = currentUrl.split("/").pop();
    lang && i18n.changeLanguage(lang === "pl" ? lang : "en");
    getAllCavitasDocs();
  }, [])
  return (
    <Page>
      <AlertMessage errorMessage={errorMessage}/>
      <section className='cavitasDocsView'>
        <header className='cavitasDocsView__header'>
          <TextSnippetOutlinedIcon className='cavitasDocsView__header__icon' />
          <p>{t("Pannel_Dashboard.Cavitasdocuments")}</p>
        </header>
        {/* {cavitasDocs.length > 0 ? cavitasDocs.map((row, index) => (
          <div className='cavitasDocsView__container' key={index}>
          <div className='cavitasDocsView__container__cards'>
            <div className='cavitasDocsView__container__cards__card'>
              <p>{row?.title}</p>
              <span>Valid from: {row?.valid_date}</span>
              <div className='cavitasDocsView__container__cards__card__icons'>
                <VisibilityOutlinedIcon className='cavitasDocsView__container__cards__card__icons__icon' />
                <ShareOutlinedIcon className='cavitasDocsView__container__cards__card__icons__icon' />
                <a href={`${API_KEY}/${row?.document?.url}`} style={{color: "black"}} target="_blank">
                <FileDownloadOutlinedIcon className='cavitasDocsView__container__cards__card__icons__icon' />
                </a>
              </div>
            </div>
          </div>
        </div>
        ))
        :
          <div style={{textAlign: "center"}}>
            <p>No Documents!</p>
          </div>
        } */}
        <div style={{display:"flex"}}>
          <div className='c' style={{ width:"100%", marginTop: "30px"}}>
          <div className='cavitasDocsView__container__cards'>
            <div className='cavitasDocsView__container__cards__card'>
              <p>{t("Cavitasdocuments.card1")}</p>
              <span>{t("Cavitasdocuments.date")}</span>
              <br />
              <div className='cavitasDocsView__container__cards__card__icons'>
                {lang === "pl" ? (
                  <>
                  <a href={BrochurecavitasPL}   style={{color: "black"}}  target="_blank"
                    rel="noreferrer">
                    <VisibilityOutlinedIcon className='cavitasDocsView__container__cards__card__icons__icon' />
                </a>
                <a href={BrochurecavitasPL}   style={{color: "black"}}  download="Broszura ubezpieczenia stomatologicznego Cavitas" target="_blank"
                  rel="noreferrer">
                  <FileDownloadOutlinedIcon className='cavitasDocsView__container__cards__card__icons__icon' />
                </a>
                </>
                ) : (
                <>
                  <a href={BrochurecavitasEN}   style={{color: "black"}}  target="_blank"
                    rel="noreferrer">
                    <VisibilityOutlinedIcon className='cavitasDocsView__container__cards__card__icons__icon' />
                </a>
                <a href={BrochurecavitasEN}   style={{color: "black"}}  download="Cavitas dental insurance brochure" target="_blank"
                  rel="noreferrer">
                  <FileDownloadOutlinedIcon className='cavitasDocsView__container__cards__card__icons__icon' />
                </a>
                </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='cavitasDocsView__container' style={{ width:"100%"}}>
          <div className='cavitasDocsView__container__cards'>
            <div className='cavitasDocsView__container__cards__card'>
            <p>{t("Cavitasdocuments.card2")}</p>
              <span>{t("Cavitasdocuments.date")}</span>
              <br />
              <div className='cavitasDocsView__container__cards__card__icons'>
                {lang === "pl" ? (
                  <>
                <a href={FOEcavitasPL}   style={{color: "black"}}  download="Szablon pliku: Lista pracowników" target="_blank"
                  rel="noreferrer">
                  <FileDownloadOutlinedIcon className='cavitasDocsView__container__cards__card__icons__icon' />
                </a>
                </>
                ) : (
                <>
                <a href={FOEcavitasEN}   style={{color: "black"}}  download="File template: list of employees" target="_blank"
                  rel="noreferrer">
                  <FileDownloadOutlinedIcon className='cavitasDocsView__container__cards__card__icons__icon' />
                </a>
                </>
                )}
              </div>
            </div>
          </div>
        </div>
        </div>
        <div style={{display:"flex"}}>
          <div className='c' style={{ width:"100%", marginTop: "30px"}}>
          <div className='cavitasDocsView__container__cards'>
            <div className='cavitasDocsView__container__cards__card'>
            <p>{t("Cavitasdocuments.card3")}</p>
              <span>{t("Cavitasdocuments.date")}</span>
              <br />
              <div className='cavitasDocsView__container__cards__card__icons'>

                {lang === "pl" ? (
                  <>
                  <a href={TCcavitasPL}   style={{color: "black"}}  target="_blank"
                    rel="noreferrer">
                    <VisibilityOutlinedIcon className='cavitasDocsView__container__cards__card__icons__icon' />
                </a>
                <a href={TCcavitasPL}   style={{color: "black"}}  download="Warunki ubezpieczenia stomatologicznego Cavitas HAM-1/2023" target="_blank"
                  rel="noreferrer">
                  <FileDownloadOutlinedIcon className='cavitasDocsView__container__cards__card__icons__icon' />
                </a>
                </>
                ) : (
                <>
                  <a href={TCcavitasEN}   style={{color: "black"}}  target="_blank"
                    rel="noreferrer">
                    <VisibilityOutlinedIcon className='cavitasDocsView__container__cards__card__icons__icon' />
                </a>
                <a href={TCcavitasEN}   style={{color: "black"}}  download="Cavitas dental insurance terms and conditions HAM-1/2023" target="_blank"
                  rel="noreferrer">
                  <FileDownloadOutlinedIcon className='cavitasDocsView__container__cards__card__icons__icon' />
                </a>
                </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='cavitasDocsView__container' style={{ width:"100%"}}>
          <div className='cavitasDocsView__container__cards'>
            <div className='cavitasDocsView__container__cards__card'>
            <p>{t("Cavitasdocuments.card4")}</p>
              <span>{t("Cavitasdocuments.date")}</span>
              <br />
              <div className='cavitasDocsView__container__cards__card__icons'>
                {lang === "pl" ? (
                  <>
                  <a href={IPIDcavitasPL}   style={{color: "black"}}  target="_blank"
                    rel="noreferrer">
                    <VisibilityOutlinedIcon className='cavitasDocsView__container__cards__card__icons__icon' />
                </a>
                <a href={IPIDcavitasPL}   style={{color: "black"}}  download="Dokument informacyjny o produkcie ubezpieczeniowym Cavitas" target="_blank"
                  rel="noreferrer">
                  <FileDownloadOutlinedIcon className='cavitasDocsView__container__cards__card__icons__icon' />
                </a>
                </>
                ) : (
                <>
                  <a href={IPIDcavitasEN}   style={{color: "black"}}  target="_blank"
                    rel="noreferrer">
                    <VisibilityOutlinedIcon className='cavitasDocsView__container__cards__card__icons__icon' />
                </a>
                <a href={IPIDcavitasEN}   style={{color: "black"}}  download="Cavitas dental insurance IPID" target="_blank"
                  rel="noreferrer">
                  <FileDownloadOutlinedIcon className='cavitasDocsView__container__cards__card__icons__icon' />
                </a>
                </>
                )}

                
              </div>
            </div>
          </div>
        </div>
        </div>
        <div style={{display:"flex"}}>
          <div className='c' style={{ width:"100%", marginTop: "30px"}}>
          <div className='cavitasDocsView__container__cards'>
            <div className='cavitasDocsView__container__cards__card'>
            <p>{t("Cavitasdocuments.card5")}</p>
              <span>{t("Cavitasdocuments.date")}</span>
              <br />
              <div className='cavitasDocsView__container__cards__card__icons'>
                {lang === "pl" ? (
                  <>
                  <a href={MICcavitasPL}   style={{color: "black"}}  target="_blank"
                    rel="noreferrer">
                    <VisibilityOutlinedIcon className='cavitasDocsView__container__cards__card__icons__icon' />
                </a>
                <a href={MICcavitasPL}   style={{color: "black"}}  download="Wzór podstawowa umowa ubezpieczenia stomatologicznego Cavitas" target="_blank"
                  rel="noreferrer">
                  <FileDownloadOutlinedIcon className='cavitasDocsView__container__cards__card__icons__icon' />
                </a>
                </>
                ) : (
                <>
                  <a href={MICcavitasEN}   style={{color: "black"}}  target="_blank"
                    rel="noreferrer">
                    <VisibilityOutlinedIcon className='cavitasDocsView__container__cards__card__icons__icon' />
                </a>
                <a href={MICcavitasEN}   style={{color: "black"}}  download="Cavitas dental insurance master insurance contract template" target="_blank"
                  rel="noreferrer">
                  <FileDownloadOutlinedIcon className='cavitasDocsView__container__cards__card__icons__icon' />
                </a>
                </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='cavitasDocsView__container' style={{ width:"100%"}}>
          <div className='cavitasDocsView__container__cards'>
            <div className='cavitasDocsView__container__cards__card'>
            <p>{t("Cavitasdocuments.card6")}</p>
              <span>{t("Cavitasdocuments.date")}</span>
              <br />
              <div className='cavitasDocsView__container__cards__card__icons'>
                {lang === "pl" ? (
                  <>
                  <a href={PolicycavitasPL}   style={{color: "black"}}  target="_blank"
                    rel="noreferrer">
                    <VisibilityOutlinedIcon className='cavitasDocsView__container__cards__card__icons__icon' />
                </a>
                <a href={PolicycavitasPL}   style={{color: "black"}}  download="Wzór harmonogramu polisy ubezpieczeniowej Cavitas" target="_blank"
                  rel="noreferrer">
                  <FileDownloadOutlinedIcon className='cavitasDocsView__container__cards__card__icons__icon' />
                </a>
                </>
                ) : (
                <>
                  <a href={PolicycavitasEN}   style={{color: "black"}}  target="_blank"
                    rel="noreferrer">
                    <VisibilityOutlinedIcon className='cavitasDocsView__container__cards__card__icons__icon' />
                </a>
                <a href={PolicycavitasEN}   style={{color: "black"}}  download="Cavitas dental insurance policy schedule template" target="_blank"
                  rel="noreferrer">
                  <FileDownloadOutlinedIcon className='cavitasDocsView__container__cards__card__icons__icon' />
                </a>
                </>
                )}
              </div>
            </div>
          </div>
        </div>
        </div>
      </section>
    </Page>
  )
}

export default CavitasDocsView
