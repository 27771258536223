import { Button } from '@mui/material'
import React, {useEffect } from "react";
import { useTranslation } from "react-i18next";
import AllCare from './Components/AllCare';
import Conversation from '../MemberLandingPage/Components/Conversation';
import i18n from '../../../config/helpers/i18n';
import { Link } from 'react-router-dom'
import Page from '../../../components/Page/Page'
import { useNavigate } from 'react-router-dom'

const Claims = () => {
  const currentUrl = window.location.href;
  const lang = currentUrl.split("/").pop();
  const { t } = useTranslation();

  useEffect(() => {
    const currentUrl = window.location.href;
    let lang = currentUrl.split("/").pop();
    lang && i18n.changeLanguage(lang === "pl" ? lang : "en");
  }, []);
  const navigate = useNavigate();
  return (
    <Page>
      <section className='claims_page'>
        <header id='claims_header'>
          <h1>{t("claim.claim_main_title")}</h1>
          <br/><br/><br/>

          <p>{t("claim.claim_title")}</p>
          <br/>
          <Button onClick={() => window.open("https://idenisonline-cavitas-poland.denisglobal.com/", '_blank')}>
          {t("claim.submit_claim")}</Button>
        </header>
      </section>
      <AllCare />
      <Conversation />
    </Page>
  )
}

export default Claims
