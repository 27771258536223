export const API_KEY = "https://cavitas.herokuapp.com";
export const FRONT_API_KEY = "https://cavitas-app.herokuapp.com";


// export const API_KEY = "http://localhost:3001";
// export const FRONT_API_KEY = "http://localhost:3000";

 export const USER_STORAGE_KEY = "CAVITAS_USER";
export const ADMIN_STORAGE_KEY = "CAVITAS_ADMIN";


