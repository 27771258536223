import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../../config/helpers/i18n';
import { Button } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import Page from "../../../components/Page/Page";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import useClientInsurance from "../../../hooks/useClientInsurance";
import ClientInfoReplyAnswerForm from "./TicketingSystemComponent/InsuredSupport/component/ClientInfoReplyAnswerForm";
import { API_KEY } from "../../../config/helpers/variables";
import AlertMessage from '../../../components/SnackbarMessages/AlertMessage';
import SuccessMessage from '../../../components/SnackbarMessages/SuccessMessage';

const InsuredPersonSupportTicket = () => {
  const { id } = useParams();
  const { getClientInfoById } = useClientInsurance();
  const [clientInfo, setClientInfo] = useState()
  const [ errorMessage, setErrorMessage ] = useState('');
  const [successMessage, setSuccessMessage] = useState();

  const getClient = async () => {
    const response = await getClientInfoById(id);
    ;
    if (response?.status < 300) {
      setClientInfo(response?.client_info);
    } else if (response?.status > 300) {
      setErrorMessage(response?.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
    }
  };


  const currentUrl = window.location.href;
  const lang = currentUrl.split("/").pop();
  const { t } = useTranslation();

  useEffect(() => {
    const currentUrl = window.location.href;
    let lang = currentUrl.split("/").pop();
    lang && i18n.changeLanguage(lang === "pl" ? lang : "en");
    getClient();
  }, [])
  return (
    <Page>
      <AlertMessage errorMessage={errorMessage}/>
      <SuccessMessage successMessage={successMessage} />
      <section className="insuredClientView">
        <header className="insuredClientView__header">
          <div className="insuredClientView__header__left">
            <MailOutlineIcon className="insuredClientView__header__left__icon" />
            <p>{t("Pannel_Dashboard_Supporttickets.Supporttitle")}</p>
          </div>
        </header>
        <br />
        <header className="insuredClientView__header">
          {/* <div className="insuredClientView__header__left">
            <Link to={`/support/view/${lang === "pl" ? "pl" : "en"}`}>
              <Button className="authentication__container__formContainer__form__loginButton_Form__Support__Ticket__ID_btn__Submit">{t("Replypannel.myinsuredticket")} #{id}              </Button>
            </Link>
          </div> */}
           <div className="insuredClientView__header__left">
            {/* <Link to={`/support/view/${lang === "pl" ? "pl" : "en"}`}> */}
              <Button className="authentication__container__formContainer__form__loginButton_Form__Support__Ticket__ID_btn__Submit">{t("Replypannel.myinsuredticket")} #{id}              </Button>
            {/* </Link> */}
          </div>
          <div className="insuredClientView__header__right">
            <Link to={`/support/view/${lang === "pl" ? "pl" : "en"}`}>
              <Button
                color="error"
                variant="outlined"
                size="small"
                style={{
                  color: "white !important",
                  marginLeft: "675px",
                  fontWeight: "bold",
                  borderRadius: "25px",
                  textTransform: "none",
                }}
              >
               {t("Replypannel.Returnticket")}
              </Button>
            </Link>
          </div>
          <div className="insuredClientView__header__right">
                          <Link
                          to={`/SubmitNewTickets/${lang === "pl" ? "pl" : "en"}`}

                          style={{ textDecoration: "none" }}>
                             <Button
                color="error"
                variant="outlined"
                size="small"
                style={{
                  color: "white !important",
                  marginLeft: "15px",
                  fontWeight: "bold",
                  borderRadius: "25px",
                  textTransform: "none",
                }}
             
                            // className="authentication__container__formContainer__form__loginButton_Form__Support__Ticket__btn"
                            >
                            {t("MysupportTickets.Submitnewticket")}
                            </Button>
                          </Link>
                        </div>
        </header>
        <br />
        <header className="insuredClientView__header">
         
          {/* <div className="insuredClientView__header__right">

            <Link
            to={`/SubmitNewTickets/${lang === "pl" ? "pl" : "en"}`}

            style={{ textDecoration: "none" }}>
              <Button
                          style={{ width: "auto" }}

              className="authentication__container__formContainer__form__loginButton_Form__Support__Ticket__ID_btn__Submit">
              {t("MysupportTickets.Submitnewticket")}
              </Button>
            </Link>
          </div> */}

        </header>
        <br /> <br />
        <Stack direction="row" spacing={7}>
          <section className="dashboard__container__content__cavitasDocs___mysupporttickets___Ticket__detail">
            <Table sx={{ minWidth: "auto" }} aria-label="simple table">
              <TableHead>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell style={{ fontWeight: "bold" }}>{t("Replypannel.Number")}:</TableCell>
                  <TableCell>{clientInfo?.id}</TableCell>
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell style={{ fontWeight: "bold" }}>{t("Replypannel.Status")}:</TableCell>
                  <TableCell>{clientInfo?.status === 'fresh' ? 'NEW' : clientInfo?.status?.toUpperCase()}</TableCell>
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell style={{ fontWeight: "bold" }}>
                  {t("Replypannel.Topic")}:
                  </TableCell>
                  <TableCell>{clientInfo?.request ? clientInfo?.request : t("Replypannel.notopic")}</TableCell>
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell style={{ fontWeight: "bold" }}>
                  {t("Replypannel.Attachement")}
                  </TableCell>
                  <TableCell>{clientInfo?.file?.url ? <a href={`${API_KEY}/api/v1/client_infos/${id}/download`}>{clientInfo?.file?.filename}</a> : `${t("Replypannel.Noattachemnt")}` }</TableCell>
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell style={{ fontWeight: "bold" }}>
                  {t("Replypannel.Createdon")}:
                  </TableCell>
                  <TableCell>
                    {clientInfo?.created_at}

                  </TableCell>
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell style={{ fontWeight: "bold" }}>
                  {t("Insuredsupport.Lastupdateon")}:
                  </TableCell>
                  <TableCell>
                    {clientInfo?.updated_at}

                  </TableCell>
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >

                </TableRow>
              </TableHead>
            </Table>
          </section>
          <div>
            {/* User side */}
            <section className="dashboard__container__content__cavitasDocs__Detail__tickets">
              <header className="dashboard__container__content__cavitasDocs__header">
                <div className="dashboard__container__content__cavitasDocs__header__iconBox">
                  <PersonOutlineOutlinedIcon />
                  <p style={{ textTransform: "none" }}>{clientInfo?.user?.name}</p>
                </div>
                <Button size="small">
                 {clientInfo?.created_at}
                </Button>
              </header>
              <div className="dashboard__container__content__cavitasDocs__Ticketsdetails">
                <table
                  className="dashboard__container__content__cavitasDocs__Ticketsdetails__table"
                  style={{ height: "auto" }}
                >
                  <thead>
                    <tr>
                      <p>
                       {clientInfo?.details}
                      </p>
                    </tr>
                  </thead>
                  <br />
                </table>
                <tbody
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderTop: "1px solid lightgray",
                    paddingTop: "1rem",
                    paddingBottom: "1rem",
                  }}
                >
                  <p>  {t("Replypannel.Attachement")} {clientInfo?.file?.url ? <a href={`${API_KEY}/api/v1/client_infos/${id}/download`}>{clientInfo?.file?.filename}</a> : `${t("Replypannel.Noattachemnt")}` }</p>
                </tbody>
              </div>
            </section>
            <br />

            {/* admin side */}

            { clientInfo?.replies && clientInfo?.replies.map((row, index) => (

              <>
              <section
                className="dashboard__container__content__cavitasDocs__Detail__tickets"
                style={{ backgroundColor: "#f4e4e4" }}
              >
                <header className="dashboard__container__content__cavitasDocs__header">
                  <div className="dashboard__container__content__cavitasDocs__header__iconBox">
                  <img
                            style={{ width: "35px", height: "auto" }}
                            src={require("../../../assets/CavitasLogo-img.png")}
                            alt=""
                          />

                    <p style={{ textTransform: "none" }}>Cavitas</p>
                  </div>
                  <Button size="small">
                  {row?.created_at}
                  </Button>
                </header>
                <div className="dashboard__container__content__cavitasDocs__Ticketsdetails">
                  <table
                    className="dashboard__container__content__cavitasDocs__Ticketsdetails__table"
                    style={{ height: "auto" }}
                  >
                    <thead>
                      <tr>
                        <p>
                          {row?.reply_text}
                        </p>
                      </tr>
                    </thead>
                    <br />
                  </table>
                  <tbody
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderTop: "1px solid lightgray",
                      paddingTop: "1rem",
                      paddingBottom: "1rem",
                    }}
                  >
                    <p>  {t("Replypannel.Attachement")} {row?.file_name ? <a href={`${API_KEY}/api/v1/client_info_replies/${row?.id}/download`}>{row?.file_name}</a> : 'No files'}</p>
                  </tbody>
                </div>
              </section>
              <br />
              {/* User side */}
              {row?.answer ?
              <section className="dashboard__container__content__cavitasDocs__Detail__tickets">
                <header className="dashboard__container__content__cavitasDocs__header">
                  <div className="dashboard__container__content__cavitasDocs__header__iconBox">
                    <PersonOutlineOutlinedIcon />
                    <p style={{ textTransform: "none" }}>{clientInfo?.user?.name}</p>
                  </div>
                  <Button size="small">
                   {row?.answer?.created_at}
                  </Button>
                </header>
                <div className="dashboard__container__content__cavitasDocs__Ticketsdetails">
                  <table
                    className="dashboard__container__content__cavitasDocs__Ticketsdetails__table"
                    style={{ height: "auto" }}
                  >
                    <thead>
                      <tr>
                        <p>
                          {row?.answer?.answer_text}
                        </p>
                      </tr>
                    </thead>
                    <br />
                  </table>
                  <tbody
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderTop: "1px solid lightgray",
                      paddingTop: "1rem",
                      paddingBottom: "1rem",
                    }}
                  >
                    <p>  {t("Replypannel.Attachement")} {row?.answer?.file_name ? <a href={`${API_KEY}/api/v1/client_info_reply_answers/${row?.answer.id}/download`}>{row?.answer?.file_name}</a> : 'No files'}</p>
                  </tbody>
                </div>
              </section>
              :
              (index === (clientInfo?.replies?.length - 1) && !row?.answer && clientInfo?.status != 'closed') ?
                row && <ClientInfoReplyAnswerForm client_info_reply={row && row} setSuccessMessage={setSuccessMessage} setErrorMessage={setErrorMessage} getClientInfoData={getClient}/>
                :
                index === (clientInfo?.replies?.length - 1) && <p style={{textAlign: 'center', color: 'red'}}>Ticket has been closed!</p>
              }
              </>

            ))

          }
          <br />
          </div>
        </Stack>
      </section>
    </Page>
  );
};

export default InsuredPersonSupportTicket;
