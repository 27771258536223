import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../config/helpers/i18n";
import AlertMessage from "../../../components/SnackbarMessages/AlertMessage";
import { useNavigate } from "react-router-dom";
import useClientInsurance from "../../../hooks/useClientInsurance";
import { Button } from "@mui/material";
import Page from "../../../components/Page/Page";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import SuccessMessage from "../../../components/SnackbarMessages/SuccessMessage";
import { Link } from "react-router-dom";
import { USER_STORAGE_KEY } from '../../../config/helpers/variables';

const InsuredClientView = () => {
  const currentUrl = window.location.href;
  const lang = currentUrl.split("/").pop();
  const { t } = useTranslation();
  const user = JSON.parse(sessionStorage.getItem(USER_STORAGE_KEY));

  useEffect(() => {
    const currentUrl = window.location.href;
    let lang = currentUrl.split("/").pop();
    lang && i18n.changeLanguage(lang === "pl" ? lang : "en");
  }, []);

  const navigate = useNavigate();
  const { getAllClientInsurance } = useClientInsurance();
  const [insuranceList, setInsuranceList] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMeassage] = useState();
  const [filter, setFilter] = useState();

  const getClientInsurance = async () => {
    const response = await getAllClientInsurance();
    if (response?.status < 300) {
      setInsuranceList(response?.client_infos);
      setFilter(response?.client_infos);
      console.log("this is right page....");
    } else {
      setErrorMessage("Something went wrong!");
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
    }
  };

  const requestSearch = (searchedVal) => {
    const filteredRows = insuranceList.filter((row) => {
      return (
        row?.member_first_name
          ?.toString()
          .toLowerCase()
          .includes(searchedVal.toString().toLowerCase()) ||
        row?.member_last_name
          ?.toString()
          .toLowerCase()
          .includes(searchedVal.toString().toLowerCase()) ||
        row?.member_email
          ?.toString()
          .toLowerCase()
          .includes(searchedVal.toString().toLowerCase()) ||
        row?.company_name
          ?.toLowerCase()
          .includes(searchedVal.toString().toLowerCase()) ||
        row?.corporate_client_name
          ?.toLowerCase()
          .includes(searchedVal.toString().toLowerCase()) ||
        row?.company_krs_number
          ?.toLowerCase()
          .includes(searchedVal.toString().toLowerCase()) ||
        row?.company_url_address
          ?.toLowerCase()
          .includes(searchedVal.toString().toLowerCase()) ||
        row?.member_pesel
          ?.toLowerCase()
          .includes(searchedVal.toString().toLowerCase()) ||
        row?.member_relation
          ?.toLowerCase()
          .includes(searchedVal.toString().toLowerCase()) ||
        row?.member_phone_number
          ?.toLowerCase()
          .includes(searchedVal.toString().toLowerCase()) ||
        row?.member_address
          ?.toLowerCase()
          .includes(searchedVal.toString().toLowerCase())
      );
    });
    if (searchedVal.length < 1) {
      setFilter(insuranceList);
    } else {
      setFilter(filteredRows);
    }
  };

  useEffect(() => {
    getClientInsurance();
  }, []);

  return (
    <Page>
      <SuccessMessage successMessage={successMessage} />
      <AlertMessage errorMessage={errorMessage} />
      <section className="insuredClientView">
        <header className="insuredClientView__header">
          <div className="insuredClientView__header__left">
            <BusinessCenterOutlinedIcon className="insuredClientView__header__left__icon" />
            
            {user?.data?.role === "member" ?
              <p>
                {t("Policyinformation.Pi_title")}
              </p>
                :
               <p>
                {t("Policyinformation.pi_heading")} ({insuranceList.length})
              </p>
            }

          </div>
          { /* <div className="insuredClientView__header__right">
            <input
              type="text"
              onChange={(e) => requestSearch(e.target.value)}
              placeholder={`${t("Insured_clients.search")}`}
            />
            <SearchOutlinedIcon className="insuredClientView__header__right__icon" />
          </div>
          */ }
        </header>
        {insuranceList.length > 0 ? (
          <div className="insuredClientView__container">
            <table>
              <thead>
                <tr>
                <th>{t("Insured_clients.Companyname")}</th>
              <th>{t("Policyinformation.PolicyYear")}</th>
              <th>{t("Policyinformation.noperson")}</th>
              <th>{t("Policyinformation.Riskinceptiondate")}</th>
              <th>{t("Policyinformation.action")}</th>
                </tr>
              </thead>
              <tbody>
                {filter.map((row, index) => (
                  <tr key={index}>
                    <td>{row?.corporate_client_name}</td>
                    <td>Year 1</td>
                    <td>Pending</td>
                    <td>{row?.inception_date}</td>
                    <td>
                      <Link to={`/insuredclient/group/${row?.id}/${lang === "pl" ? "pl" : "en"}` } >{t("Pannel_Dashboard_insuredperson.Opengroup")}</Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <p
              style={{
                marginTop: "10%",
                fontWeight: "bold",
                fontSize: "1.2rem",
              }}
            >
              {t("Insured_clients.noclient")}
            </p>
          </div>
        )}
      </section>
    </Page>
  );
};

export default InsuredClientView;
