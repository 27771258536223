import React, {useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../config/helpers/i18n';

const BAT = () => {
  const currentUrl = window.location.href;
  const lang = currentUrl.split("/").pop();
  const { t } = useTranslation();

  useEffect(() => {
    const currentUrl = window.location.href;
    let lang = currentUrl.split("/").pop();
    lang && i18n.changeLanguage(lang === "pl" ? lang : "en");
  }, [])

  

  return (
    <section className="faqTab">
      <div className="landingPage__dentaltInsurance__container__details">
        <h1
          className="landingPage__smartInsurance__container__details__heading2"
          style={{ fontSize: "40px", fontWeight: 600 }}
        >
          {" "}
          {t("BrokerAgreement.Main_heading")}
        </h1>
      </div>
      <div
        style={{
          textAlign: "justify",
          width: "100%",
          fontSize: "16px",
          fontWeight: "400",
        }}
      > 
      <br />
        <h2 style={{ fontSize: "16px", fontWeight: 500 }}>
          {t("BrokerAgreement.Sub_heading_01")}{" "}
        </h2>
        <p>
          {t("BrokerAgreement.Para_No_1")}
          <br />
          <br />

          <h3 style={{ fontSize: "16px", fontWeight: 500 }}>
            {" "}
            {t("BrokerAgreement.Sub_heading_02")}{" "}
          </h3>

          1.1 <span style={{ fontSize: "16px", fontWeight: 500 }}>
            {t("BrokerAgreement.s1")}
          </span>{" "}
          {t("BrokerAgreement.Para_No_2")}
          <br />
          1.2 <span style={{ fontSize: "16px", fontWeight: 500 }}>
            {t("BrokerAgreement.s2")}
          </span>{" "}
          {t("BrokerAgreement.Para_No_3")}
          <br />
          1.3 {t("BrokerAgreement.Para_No_4")}
          <br />
          1.4 <span style={{ fontSize: "16px", fontWeight: 500 }}>
            {t("BrokerAgreement.s3")}
          </span>{" "}
          {t("BrokerAgreement.Para_No_5")}
          <br />
          1.5 {t("BrokerAgreement.Para_No_6")}
          <br />
          1.6 {t("BrokerAgreement.Para_No_7")}
          <br />
          1.7 {t("BrokerAgreement.Para_No_8")} 
          <br />
          1.8 {t("BrokerAgreement.Para_No_9")}
          <br />
          1.9 {t("BrokerAgreement.Para_No_10")}
          <br />
          1.10 {t("BrokerAgreement.Para_No_11")}
          <br />
          1.11 {t("BrokerAgreement.Para_No_12")}
          <br />
          1.12 {t("BrokerAgreement.Para_No_13")}
          <br />
          1.13 {t("BrokerAgreement.Para_No_14")}
          <br />
          1.14 {t("BrokerAgreement.Para_No_15")}
          <br />
          1.15 {t("BrokerAgreement.Para_No_16")}
          <br />
          1.16 {t("BrokerAgreement.Para_No_17")}
          <br />
          1.17 {t("BrokerAgreement.Para_No_18")}
          <br /> <br />

          <h3 style={{ fontSize: "16px", fontWeight: 500 }}>
            {" "}
            2. {t("BrokerAgreement.Sub_heading_03")}{" "}
          </h3>

          2.1 {t("BrokerAgreement.Para_No_19")}
          <br />
          2.2 {t("BrokerAgreement.Para_No_20")}
          <br />
          2.3 {t("BrokerAgreement.Para_No_21")}
          <br />
          2.4 {t("BrokerAgreement.Para_No_22")}
          <br />
          2.5 {t("BrokerAgreement.Para_No_23")}
          <br />
          2.6 {t("BrokerAgreement.Para_No_24")}
          <br />
          2.7 {t("BrokerAgreement.Para_No_25")}
          <br />
          2.8 {t("BrokerAgreement.Para_No_26")}
          <br /> <br />
          
          <h3 style={{ fontSize: "16px", fontWeight: 500 }}>
            {" "}
            3. {t("BrokerAgreement.Sub_heading_04")}{" "}
          </h3>
          
          <h3 style={{ fontSize: "16px", fontWeight: 500 }}>
            {" "}
            3.1 {t("BrokerAgreement.Sub_heading_05")}{" "}
          </h3>

          3.1.1 {t("BrokerAgreement.Para_No_27")}
          <br />
          3.1.2 {t("BrokerAgreement.Para_No_28")}
          <br />
          3.1.3 {t("BrokerAgreement.Para_No_29")}
          <br />
          3.1.4 {t("BrokerAgreement.Para_No_30")}
          <br />
          3.1.5 {t("BrokerAgreement.Para_No_31")}
          <br />
          3.1.6 {t("BrokerAgreement.Para_No_32")}
          <br />
          3.1.7 {t("BrokerAgreement.Para_No_33")}
          <br />
          3.1.8 {t("BrokerAgreement.Para_No_34")}
          <br /> <br />
          
          <h3 style={{ fontSize: "16px", fontWeight: 500 }}>
            {" "}
            3.2 {t("BrokerAgreement.Sub_heading_06")}{" "}
          </h3>

          3.2.1 {t("BrokerAgreement.Para_No_35")}
          <br />
          3.2.2 {t("BrokerAgreement.Para_No_36")}
          <br />
          3.2.3 {t("BrokerAgreement.Para_No_37")}
          <br />
          3.2.4 {t("BrokerAgreement.Para_No_38")}
          <br />
          3.2.5 {t("BrokerAgreement.Para_No_39")}
          <br />
          3.2.6 {t("BrokerAgreement.Para_No_40")}
          <br /> <br />
          
          <h3 style={{ fontSize: "16px", fontWeight: 500 }}>
            {" "}
            3.3 {t("BrokerAgreement.Sub_heading_07")}{" "}
          </h3>

          3.3.1 {t("BrokerAgreement.Para_No_41")}
          <br /> <br />
          a. {t("BrokerAgreement.Para_No_42")}
          <br />
          b. {t("BrokerAgreement.Para_No_43")}
          <br />
          c. {t("BrokerAgreement.Para_No_44")}
          <br />
          d. {t("BrokerAgreement.Para_No_45")}
          <br />
          e. {t("BrokerAgreement.Para_No_46")}
          <br />
          f. {t("BrokerAgreement.Para_No_47")}
          <br />
          g. {t("BrokerAgreement.Para_No_48")}
          <br /> <br />
          3.3.2 {t("BrokerAgreement.Para_No_49")}
          <br />
          3.3.3 {t("BrokerAgreement.Para_No_50")}
          <br />
          3.3.4 {t("BrokerAgreement.Para_No_51")}
          <br />
          3.3.5 {t("BrokerAgreement.Para_No_52")}
          <br />
          3.3.6 {t("BrokerAgreement.Para_No_53")}
          <br />
          3.3.7 {t("BrokerAgreement.Para_No_54")}
          <br /> <br />
          
          <h3 style={{ fontSize: "16px", fontWeight: 500 }}>
            {" "}
            4. {t("BrokerAgreement.Sub_heading_08")}{" "}
          </h3>
          <h3 style={{ fontSize: "16px", fontWeight: 500 }}>
            {" "}
            4.1 {t("BrokerAgreement.Sub_heading_09")}{" "}
          </h3>

          4.1.1 {t("BrokerAgreement.Para_No_55")}
          <br />
          4.1.2 {t("BrokerAgreement.Para_No_56")}
          <br />
          4.1.3 {t("BrokerAgreement.Para_No_57")}
          <br />
          4.1.4 {t("BrokerAgreement.Para_No_58")}
          <br />
          4.1.5 {t("BrokerAgreement.Para_No_59")}
          <br />
          4.1.6 {t("BrokerAgreement.Para_No_60")}
          <br />
          4.1.7{t("BrokerAgreement.Para_No_61")}
          <br />
          4.1.8 {t("BrokerAgreement.Para_No_62")}
          <br />
          4.1.9 {t("BrokerAgreement.Para_No_63")}
          <br />
          4.1.10 {t("BrokerAgreement.Para_No_64")}
          <br />
          4.1.11 {t("BrokerAgreement.Para_No_65")}
          <br /> <br />

          <h3 style={{ fontSize: "16px", fontWeight: 500 }}>
            {" "}
            4.2 {t("BrokerAgreement.Sub_heading_10")}{" "}
          </h3>

          4.2.1 {t("BrokerAgreement.Para_No_66")}
          <br />
          4.2.2 {t("BrokerAgreement.Para_No_67")}
          <br />
          4.2.3 {t("BrokerAgreement.Para_No_68")}
          <br />
          4.2.4 {t("BrokerAgreement.Para_No_69")}
          <br />
          4.2.5 {t("BrokerAgreement.Para_No_70")}
          <br />
          4.2.6 {t("BrokerAgreement.Para_No_71")}
          <br />
          4.2.7 {t("BrokerAgreement.Para_No_72")}
          <br />
          4.2.8 {t("BrokerAgreement.Para_No_73")}
          <br />
          4.2.9 {t("BrokerAgreement.Para_No_74")}
          <br />
          4.2.10 {t("BrokerAgreement.Para_No_75")}
          <br />
          4.2.11 {t("BrokerAgreement.Para_No_76")}
          <br />
          4.2.12 {t("BrokerAgreement.Para_No_77")}
          <br /> <br />

          <h3 style={{ fontSize: "16px", fontWeight: 500 }}>
            {" "}
            5. {t("BrokerAgreement.Sub_heading_11")}{" "}
          </h3>

          5.1 {t("BrokerAgreement.Para_No_78")}
          <br />
          5.2 {t("BrokerAgreement.Para_No_79")}
          <br />
          5.3 {t("BrokerAgreement.Para_No_80")}
          <br />
          5.4 {t("BrokerAgreement.Para_No_81")}
          <br /> <br />

          <h3 style={{ fontSize: "16px", fontWeight: 500 }}>
            {" "}
            6. {t("BrokerAgreement.Sub_heading_12")}{" "}
          </h3>

          6.1 {t("BrokerAgreement.Para_No_82")}
          <br />
          6.2 {t("BrokerAgreement.Para_No_83")}
          <br />
          6.3 {t("BrokerAgreement.Para_No_84")}
          <br /> <br />

          <h3 style={{ fontSize: "16px", fontWeight: 500 }}>
            {" "}
            7. {t("BrokerAgreement.Sub_heading_13")}{" "}
          </h3>

          7.1 {t("BrokerAgreement.Para_No_85")}
          <br />
          7.2 {t("BrokerAgreement.Para_No_86")}
          <br />
          7.3 {t("BrokerAgreement.Para_No_87")}
          <br />
          7.4 {t("BrokerAgreement.Para_No_88")}
          <br />
          7.5 {t("BrokerAgreement.Para_No_89")}
          <br />
          7.6 {t("BrokerAgreement.Para_No_90")}
          <br />
          7.7 {t("BrokerAgreement.Para_No_91")}
          <br />
          7.8 {t("BrokerAgreement.Para_No_92")}
          <br /><br />

          <h3 style={{ fontSize: "16px", fontWeight: 500 }}>
            {" "}
            8. {t("BrokerAgreement.Sub_heading_14")}{" "}
          </h3>

          8.1 {t("BrokerAgreement.Para_No_93")}
          <br />
          8.2 {t("BrokerAgreement.Para_No_94")}
          <br />
          8.3 {t("BrokerAgreement.Para_No_95")}
          <br />
          8.4 {t("BrokerAgreement.Para_No_96")}
          <br />
          8.5 {t("BrokerAgreement.Para_No_97")}
          <br />

        </p>
      </div>
    </section>
  )
}

export default BAT
