import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../../config/helpers/i18n";
import { useLocation } from 'react-router-dom';
import Page from '../../../../components/Page/Page';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import PolicyInfo from '../Components/PolicyInfo';
import Coverage from '../Components/Coverage';
import Payment from '../Components/Payment';
import Commission from '../Components/Commission';
import PolicyDocuments from '../Components/PolicyDocuments';
import InsuredPerson from '../Components/InsuredPerson';
import useClientInsurance from "../../../../hooks/useClientInsurance";
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import { USER_STORAGE_KEY } from '../../../../config/helpers/variables';
import { Link, useParams } from "react-router-dom";


const InsuredClientGroup = () => {
  const { id } = useParams();
  const { getClientInfoById } = useClientInsurance();
  const [clientInfo, setClientInfo] = useState();
  const [ errorMessage, setErrorMessage ] = useState('');
  const [successMessage, setSuccessMessage] = useState();

  let row;
  const { t } = useTranslation();
  const location = useLocation();
  const user = JSON.parse(sessionStorage.getItem(USER_STORAGE_KEY));
  if (location.state != null){
    row = location.state; 
  }

  const getClient = async () => {
    const response = await getClientInfoById(id);
    ;
    if (response?.status < 300) {
      setClientInfo(response?.client_info);
    } else if (response?.status > 300) {
      setErrorMessage(response?.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
    }
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    let lang = currentUrl.split("/").pop();
    lang && i18n.changeLanguage(lang === "pl" ? lang : "en");
    getClient();
  }, []);

  return (
    <Page>
      <section className='insuredClientGroup'>
        {user?.data?.role === "broker" ? <>
          {/* <div onClick={() => navigate(-1)} className='insuredClientGroup__back'>
            <ArrowBackIosNewOutlinedIcon className='insuredClientGroup__back__icon' />
            Back
          </div> */}
          <header className='insuredClientGroup__header'>
            <BusinessCenterOutlinedIcon className='insuredClientGroup__header__icon' />
            <p>{t("Policyinformation.pi_maintitle")} - [{clientInfo?.corporate_client_name}] </p>
          </header>
        </>
          :
          <>
            <header className='insuredClientGroup__header'>
              <GppGoodOutlinedIcon className='insuredClientGroup__header__icon' />
              <p>{t("Policyinformation.Pi_title")}</p>
            </header>
          </>
        }
        <div className='insuredClientGroup__container'>
          <div className='insuredClientGroup__container__cardsBox'>
            <div className='insuredClientGroup__container__cardsBox__left'>
              {/* { (row != null) && <PolicyInfo user={user} insuredClient={row} />} */}
              <PolicyInfo policyInfo={clientInfo} user={user}/>
              <PolicyDocuments policyInfo={clientInfo} user={user} />
            </div>
            <div className='insuredClientGroup__container__cardsBox__right'>
              <Coverage user={user} policyInfo={clientInfo} />
              <Payment />
              {/* <Commission user={user} /> */}
            </div>
          </div>

          {user?.data?.role === "employ" || user?.data?.role === "member" ?
            <div className='insuredClientGroup__container2'>
              <InsuredPerson user={user} />
            </div>
            :
            ""
          }

          

        </div>
      </section>
    </Page>
  )
}

export default InsuredClientGroup
