import React from 'react';
import Page from "../../../components/Page/Page";
import InsuredPerson from './SupportComponent/InsuredPerson';

const AdminInsuredClientView = () => {
  return (
    <Page>
       <InsuredPerson />
    </Page>
  )
}

export default AdminInsuredClientView