import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../../config/helpers/i18n";
import Page from '../../../../components/Page/Page';
import PolicyInfo from '../Components/PolicyInfo';
import Coverage from '../Components/Coverage';
import Payment from '../Components/Payment';
import InsuredPerson from '../Components/InsuredPerson';
import useClientInsurance from "../../../../hooks/useClientInsurance";
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import { Link, useParams } from "react-router-dom";


const AdminInsuredClientGroup = () => {
  const { id } = useParams();
  const { getClientInfoByIdAdmin } = useClientInsurance();
  const [clientInfo, setClientInfo] = useState();
  const [ errorMessage, setErrorMessage ] = useState('');
  const [successMessage, setSuccessMessage] = useState();


  const getClient = async () => {
    const response = await getClientInfoByIdAdmin(id);
    ;
      console.log("vvvvvvvvvv")
      console.log(response)
    if (response?.status < 300) {
      setClientInfo(response?.client_info);
    } else if (response?.status > 300) {
      setErrorMessage(response?.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
    }
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    let lang = currentUrl.split("/").pop();
    lang && i18n.changeLanguage(lang === "pl" ? lang : "en");
    getClient();
  }, []);

  return (
    <Page>
      <section className='insuredClientGroup'>
            <header className='insuredClientGroup__header'>
              <GppGoodOutlinedIcon className='insuredClientGroup__header__icon' />
              <p>Group policy information</p>
            </header>
        <div className='insuredClientGroup__container'>
          <div className='insuredClientGroup__container__cardsBox'>
            <div className='insuredClientGroup__container__cardsBox__left'>
              <PolicyInfo policyInfo={clientInfo} />
            </div>
            <div className='insuredClientGroup__container__cardsBox__right'>
              <Coverage policyInfo={clientInfo} />
              <Payment policyInfo={clientInfo} />
            </div>
          </div>

            <div className='insuredClientGroup__container2'>
              <InsuredPerson />
            </div>

          

        </div>
      </section>
    </Page>
  )
}

export default AdminInsuredClientGroup
