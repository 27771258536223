import React, { useState, useEffect } from 'react'
import AlertMessage from '../../../components/SnackbarMessages/AlertMessage';
import useClientInsurance from '../../../hooks/useClientInsurance';
import Page from "../../../components/Page/Page";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import { Link } from "react-router-dom";


const AdminInsuredClientView = () => {
  const { getAllPoliciesAdmin } = useClientInsurance();
  const [clientInfoList, setClientInfoList] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null)

  const getClientInsurance = async () => {

    const response = await getAllPoliciesAdmin();
    if (response.status < 300) {
      setClientInfoList(response.client_infos);
    } else {
      setErrorMessage('Something went wrong!')
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
    }
  }

  useEffect(() => {
    getClientInsurance();
  }, []);

  return (
    <Page>
      <AlertMessage errorMessage={errorMessage} />
      <section className='insuredClientView'>
        <header className='insuredClientView__header'>
          <div className='insuredClientView__header__left'>
            <BusinessCenterOutlinedIcon className='insuredClientView__header__left__icon' />
            <p>Group policy information ({clientInfoList.length})</p>
          </div>
          <div className='insuredClientView__header__right'>
            <input type='text' placeholder='Search' />
            <SearchOutlinedIcon className='insuredClientView__header__right__icon' />
          </div>
        </header>
        <div className='insuredClientView__container'>
          <table >
            <thead>
              <tr>
                <th>Company name</th>
                <th>Policy year</th>
                <th>No of members</th>
                <th>Risk start date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
            {clientInfoList.length > 0 ? clientInfoList.map((row, index) => (
                <tr>
                  <td>{row?.corporate_client_name}</td>
                  <td>Year 1</td>
                  <td>Pending</td>
                  <td>{row?.inception_date}</td>
                  <td><Link to={`/admin/insuredclient/group/${row?.id}/en` } >OPEN A GROUP</Link></td>
                {/*  <td><ClientInfoUpdate client_id={row?.id} getClientInsurance={getClientInsurance}/>
                  </td>
                 <td style={{ display: "flex", alignItems: 'center', justifyContent: 'space-around' }}>
                  <Button color='success' variant='contained' size='small' style={{ color: "white" }} onClick={() => navigate("/admin/dashboard")}>Accept</Button>
                  <InsuredClientRejectModal />
                </td> */}
              </tr>
              ))
              :
                <div>
                  <p>No records.</p>
                </div>}
            </tbody>
          </table>
        </div>
      </section>
    </Page>
  )
}

export default AdminInsuredClientView
