import React, { useState, useEffect } from 'react'
import Page from '../../../components/Page/Page';
import useClientInsurance from '../../../hooks/useClientInsurance';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import MemberInfo from './Components/MemberInfo';
import PaymentInfo from './Components/PaymentInfo';
import { Link, useParams } from "react-router-dom";


const InsuredMemberDetail = () => {
  const { id } = useParams();
  const [clientInfo, setClientInfo] = useState();
  const [ errorMessage, setErrorMessage ] = useState('');
  const [successMessage, setSuccessMessage] = useState();

  const { getInsuredClientsByAdmin } = useClientInsurance();
  const { getinsuredClientInfoByIdAdmin } = useClientInsurance();
  const [insuredClientsList, setInsuredClientsList] = useState();


  const getInsuredClientList = async () => {
    const response = await getInsuredClientsByAdmin();
    if (response.status < 300) {
      console.log("ressssssssshuuuuuuuuuuuuuu")
      console.log(response)
      setInsuredClientsList(response?.insured_clients);
    } else {
      setErrorMessage('Something went wrong!')
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
    }
  }
  const getClienthf = async () => {
    const response = await getinsuredClientInfoByIdAdmin(id);
      console.log("dddddddddddddd")
      console.log(response)


    if (response?.status < 300) {
      setClientInfo(response?.insured_client_info);
    } else if (response?.status > 300) {
      setErrorMessage(response?.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
    }
  }
  useEffect(() => {
    getInsuredClientList();
    getClienthf();
  }, []);

  



  return (
    <Page>
      <section className='insuredClientGroup'>
        <header className='insuredClientGroup__header'>
            <BusinessCenterOutlinedIcon className='insuredClientGroup__header__icon' />
            <p>Insured Person - {clientInfo?.member_first_name} {clientInfo?.member_last_name} </p>
          </header>
        <div className='insuredClientGroup__container'>
          <div className='insuredClientGroup__container__cardsBox'>
            <div className='insuredClientGroup__container__cardsBox__left'>
              <MemberInfo policyInfo={clientInfo} />
            </div>
            <div className='insuredClientGroup__container__cardsBox__right'>
              <PaymentInfo policyInfo={clientInfo} />
            </div>
          </div>
        </div>
      </section>
    </Page>
  )
}

export default InsuredMemberDetail
