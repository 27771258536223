import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../config/helpers/i18n';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import useClientInsurance from '../../../../hooks/useClientInsurance';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

const InsuredPerson = ({user}) => {
  const currentUrl = window.location.href;
  const lang = currentUrl.split("/").pop();
  const { t } = useTranslation();

  useEffect(() => {
    const currentUrl = window.location.href;
    let lang = currentUrl.split("/").pop();
    lang && i18n.changeLanguage(lang === "pl" ? lang : "en");
  }, [])
  const navigate = useNavigate();
  const { getInsuredClients } = useClientInsurance();
  const [insuranceList, setInsuranceList] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null)
  const [filter, setFilter] = useState([]);


  const getClientInsurance = async () => {
    const response = await getInsuredClients();
    if (response?.status < 300) {
      setInsuranceList(response?.insured_clients);
      setFilter(response?.insured_clients)
    } else {
      setErrorMessage('Something went wrong!')
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
    }
  }

  const requestSearch = (searchedVal) => {
    const filteredRows = insuranceList.filter((row) => {
        return (
          row?.member_first_name?.toString().toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
          row?.member_last_name?.toString().toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
          row?.member_email?.toString().toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
          row?.company_name?.toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
          row?.company_krs_number?.toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
          row?.company_url_address?.toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
          row?.member_pesel?.toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
          row?.member_relation?.toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
          row?.member_phone_number?.toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
          row?.member_address?.toLowerCase().includes(searchedVal.toString().toLowerCase())
          )
    });
    if (searchedVal.length < 1) {
        setFilter(insuranceList)
    }
    else {
        setFilter(filteredRows)
    }
  };

  useEffect(() => {
    getClientInsurance();
  }, []);

  return (
    <section className='insuredClientGroup__container2__insuredPerson'>
      <header className='insuredClientGroup__container2__insuredPerson__header'>
        {user?.data?.role === "member" ?
        <p>{t("Policyinformation.related_members")}</p>
        :
        <p>{t("Policyinformation.noinsured")}</p>
        }
         {/* <div>
          <input type="text" onChange={(e) => requestSearch(e.target.value)}

          placeholder={`${t("Insured_clients.search")}`}
          />
          <SearchOutlinedIcon className='insuredClientGroup__container2__insuredPerson__header__icon' />
        </div> */}
      </header>
      <div className='insuredClientGroup__container2__insuredPerson__tableBox'>
        <table>
          <thead>
            <tr>
              <th>{t("Policyinformation.Typeofinsurance")}</th>
              <th>{t("Insured_clients.Policyrefno")}</th>
              <th>{t("Policyinformation.PolicyYear")}</th>
              <th>{t("Policyinformation.Riskinceptiondate")}</th>
              <th>{t("Policyinformation.Riskexpirydate")}</th>
              <th>{t("Policyinformation.Riskrenewaldate")}</th>
              <th>{t("Policyinformation.Insuranceplan")}</th>
              <th>{t("Insured_clients.Memberfirstname")}</th>
              <th>{t("Insured_clients.MemberLastname")}</th>
              <th>{t("Insured_clients.Memberrelation")}</th>
              <th>{t("Insured_clients.MemberPESEL")}</th>
              <th>{t("Insured_clients.cover_type")}</th>
              <th>{t("Insured_clients.AnnualPremium")}</th>
              <th>{t("Insured_clients.MonthlyPremium")}</th>

            </tr>
          </thead>
          <tbody>
          {filter?.map((row, index) => (
                <tr key={index}>
                  <td>{row?.type_of_insurance}</td>
                  <td></td>
                  <td>Year 1</td>
                  <td>{row?.risk_inception_date}</td>
                  <td>{row?.risk_expiry_date}</td>
                  <td>{row?.risk_renewal_date}</td>
                  <td>{row?.insurance_plan}</td>
                  <td>{row?.member_first_name}</td>
                  <td>{row?.member_last_name}</td>
                  <td>{row?.member_relation}</td>
                  <td>{row?.member_pesel}</td>
                  <td>FAMILY</td>
                  <td>{row?.annual_gwp}</td>
                  <td>{row?.monthly_np}</td>

                </tr>
            ))}

            {[1,2,3,4,5]?.map((index) => (
                <tr key={index} className="height_20">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
            ))}


          </tbody>
        </table>
      </div>
    </section>
  )
}

export default InsuredPerson
