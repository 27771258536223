import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../../config/helpers/i18n";

const PolicyInfo = ({ user, policyInfo, insuredClient = '' }) => {

  const currentUrl = window.location.href;
  const lang = currentUrl.split("/").pop();
  const { t } = useTranslation();

  useEffect(() => {
    const currentUrl = window.location.href;
    let lang = currentUrl.split("/").pop();
    lang && i18n.changeLanguage(lang === "pl" ? lang : "en");
  }, []);

    return (
    <div className='insuredClientGroup__container__cardsBox__card'>
      <div className='insuredClientGroup__container__cardsBox__card__top'>
        <p>{t("Policyinformation.pi_heading")}</p>
      </div>
      <div className='insuredClientGroup__container__cardsBox__card__detailsBox'>
        
        <div className='insuredClientGroup__container__cardsBox__card__detailsBox__data'>
          <p className='insuredClientGroup__container__cardsBox__card__detailsBox__data__name'>{t("Insured_clients.Policyrefno")}:</p>
          <p></p>
        </div>
        <div className='insuredClientGroup__container__cardsBox__card__detailsBox__data'>
          <p className='insuredClientGroup__container__cardsBox__card__detailsBox__data__name'>{t("Insured_clients.Companyname")}:</p>
          <p>{policyInfo?.corporate_client_name}</p>
        </div>
        <div className='insuredClientGroup__container__cardsBox__card__detailsBox__data'>
          <p className='insuredClientGroup__container__cardsBox__card__detailsBox__data__name'>{t("Insured_clients.Companykrsnumber")}:</p>
          <p>{policyInfo?.company_krs_number ? policyInfo?.company_krs_number : ""}</p>
        </div>
        <div className='insuredClientGroup__container__cardsBox__card__detailsBox__data'>
          <p className='insuredClientGroup__container__cardsBox__card__detailsBox__data__name'>{t("Insured_clients.Companyaddress")}:</p>
          <p>{policyInfo?.company_website ? policyInfo?.company_website : ""}</p>
        </div>
        <div className='insuredClientGroup__container__cardsBox__card__detailsBox__data'>
          <p className='insuredClientGroup__container__cardsBox__card__detailsBox__data__name'>
          {t("Policyinformation.Riskinceptiondate")}:</p>
          <p>{policyInfo?.inception_date}</p>

        </div>
        <div className='insuredClientGroup__container__cardsBox__card__detailsBox__data'>
          <p className='insuredClientGroup__container__cardsBox__card__detailsBox__data__name'>
               {t("Policyinformation.Riskexpirydate")}:</p>
          <p>{policyInfo?.inception_date}</p>
        </div>
        <div className='insuredClientGroup__container__cardsBox__card__detailsBox__data'>
          <p className='insuredClientGroup__container__cardsBox__card__detailsBox__data__name'>
        {t("Policyinformation.Riskrenewaldate")}:</p>
          <p>{policyInfo?.inception_date}</p>
        </div>
        {user?.data?.role === "member" ?
          <></>
          :
          <>
          <div className='insuredClientGroup__container__cardsBox__card__detailsBox__data'>
            <p className='insuredClientGroup__container__cardsBox__card__detailsBox__data__name'>
            {t("Policyinformation.noinsuredemploye")}:</p>
            <p>50</p>
          </div>
          <div className='insuredClientGroup__container__cardsBox__card__detailsBox__data'>
            <p className='insuredClientGroup__container__cardsBox__card__detailsBox__data__name'>
            {t("Policyinformation.noinsuredperson")}:</p>
            <p>100</p>
          </div>
          </>

        }
        <div className='insuredClientGroup__container__cardsBox__card__detailsBox__data'>
          <p className='insuredClientGroup__container__cardsBox__card__detailsBox__data__name'>
          {t("Policyinformation.Mandatedbroker")}
:</p>
          <p>{policyInfo?.referenced_broker_name}</p>
        </div>
      </div>
    </div>
  )
}

export default PolicyInfo
