import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../../../../config/helpers/i18n";

const CP = () => {
  const currentUrl = window.location.href;
  const lang = currentUrl.split("/").pop();
  const { t } = useTranslation();

  useEffect(() => {
    const currentUrl = window.location.href;
    let lang = currentUrl.split("/").pop();
    lang && i18n.changeLanguage(lang === "pl" ? lang : "en");
  }, []);

  return (
    <section className="faqTab">
      <div className="landingPage__dentaltInsurance__container__details">
        {/* <h1 className='landingPage__smartInsurance__container__details__heading'> Privacy Policy </h1> */}
        <h1
          className="landingPage__smartInsurance__container__details__heading2"
          style={{ fontSize: "40px", fontWeight: 600 }}
        >
          {t("ComplaintProcedure.Main_heading")}
        </h1>
      </div>
      <div
        style={{
          textAlign: "justify",
          width: "100%",
          fontSize: "16px",
          fontWeight: 400,
        }}
      >
        <p>
          <br />
          <h3 style={{ fontSize: "16px", fontWeight: 500 }}>{t("ComplaintProcedure.Sub_heading_01")}</h3>
          {/* <Link style={{cursor:"pointer"}} to="https://cavitas.pl">https://cavitas.pl</Link>{" "} */}
          {t("ComplaintProcedure.Para_No_1")}
          <br />
          <br />
          <h3 style={{ fontSize: "16px", fontWeight: 500 }}>{t("ComplaintProcedure.Sub_heading_02")}</h3>
          {t("ComplaintProcedure.Para_No_2")}
          <br />
          {t("ComplaintProcedure.Para_No_3")}
          <br />
          {t("ComplaintProcedure.Para_No_4")}
          <br />
          {t("ComplaintProcedure.Para_No_5")}
          <br />
          {t("ComplaintProcedure.Para_No_6")}
          <br />
          <br />
          <h3 style={{ fontSize: "16px", fontWeight: 500 }}>
            {t("ComplaintProcedure.Sub_heading_03")}
          </h3>
          {t("ComplaintProcedure.Para_No_7")}
          <br />
          {t("ComplaintProcedure.Para_No_8")}
          <br />
         {t("ComplaintProcedure.Para_No_9")}
          <div style={{ marginLeft: "10px" }}>
            <br /> {t("ComplaintProcedure.Para_No_10")}
            <br /> {t("ComplaintProcedure.Para_No_11")}
            <br />{t("ComplaintProcedure.Para_No_12")}
            <br /> {t("ComplaintProcedure.Para_No_13")}
          </div>
          <br /> {t("ComplaintProcedure.Para_No_14")}
          <br />
          {t("ComplaintProcedure.Para_No_15")}
          <br />
          {t("ComplaintProcedure.Para_No_16")}
          <br />
          {t("ComplaintProcedure.Para_No_17")}
          <br /> <br />
          <h3 style={{ fontSize: "16px", fontWeight: 500 }}>
            {t("ComplaintProcedure.Sub_heading_04")}
          </h3>
          {t("ComplaintProcedure.Para_No_18")}
          <br />
          {t("ComplaintProcedure.Para_No_19")}
          <br />
          {t("ComplaintProcedure.Para_No_20")}
          <div style={{ marginLeft: "10px" }}>
            <br /> {t("ComplaintProcedure.Para_No_21")}
            <br />
            {t("ComplaintProcedure.Para_No_22")}
            <br />
            {t("ComplaintProcedure.Para_No_23")}
            <br />
            {t("ComplaintProcedure.Para_No_24")}
          </div>
          <br /> {t("ComplaintProcedure.Para_No_25")}
          <br /> {t("ComplaintProcedure.Para_No_26")}
          <br /> <br />
          <h3 style={{ fontSize: "16px", fontWeight: 500 }}>
            {t("ComplaintProcedure.Sub_heading_05")}
          </h3>
          {t("ComplaintProcedure.Para_No_27")}
          <br />
          <strong>
            {t("ComplaintProcedure.Para_No_28")}
          </strong>
          <br />
          <strong>{t("ComplaintProcedure.B1")}</strong> {t("ComplaintProcedure.Para_No_29")}
          <br />
          <strong>{t("ComplaintProcedure.B2")}</strong> {t("ComplaintProcedure.Para_No_30")}
          <br />
          <br />
          <h3 style={{ fontSize: "16px", fontWeight: 500 }}>
            {t("ComplaintProcedure.Sub_heading_06")}
          </h3>
          {t("ComplaintProcedure.Para_No_31")}
          <br />
          {t("ComplaintProcedure.Para_No_32")}
          <br />
          <br />
          <h3 style={{ fontSize: "16px", fontWeight: 500 }}>
            {t("ComplaintProcedure.Sub_heading_07")}
          </h3>
          {t("ComplaintProcedure.Para_No_33")}
          <br />
          {t("ComplaintProcedure.Para_No_34")}
          <br />
          {t("ComplaintProcedure.Para_No_35")}
          <br />
          {t("ComplaintProcedure.Para_No_36")}
          <br />
          <br />
        </p>
      </div>
    </section>
  );
};

export default CP;
