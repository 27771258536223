import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Page from '../../../components/Page/Page';
import AlertMessage from "../../../components/SnackbarMessages/AlertMessage";
import SuccessMessage from '../../../components/SnackbarMessages/SuccessMessage';
import useClientInsurance from '../../../hooks/useClientInsurance';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ExportCsv from './Components/ExportCsv';
import { Link } from "react-router-dom";


const AdminFileApproval = () => {
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState(null);
  const { getInsuredClientsByAdmin } = useClientInsurance();
  const [insuredClientsList, setInsuredClientsList] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null)
  const [filter, setFilter] = useState();


  const getInsuredClientList = async () => {
    const response = await getInsuredClientsByAdmin();
    if (response.status < 300) {
      console.log(response)
      setInsuredClientsList(response?.insured_clients);
      setFilter(response?.insured_clients)
    } else {
      setErrorMessage('Something went wrong!')
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
    }
  }
  useEffect(() => {
    getInsuredClientList();
  }, []);

  const requestSearch = (searchedVal) => {

    const filteredRows = insuredClientsList.filter((row) => {
      if(searchedVal !== null){
        return  (
          row?.member_first_name?.toString().toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
          row?.member_last_name?.toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
          row?.member_email?.toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
          row?.company_name?.toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
          row?.company_krs_number?.toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
          row?.company_url_address?.toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
          row?.member_pesel?.toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
          row?.member_relation?.toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
          row?.member_phone_number?.toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
          row?.member_address?.toLowerCase().includes(searchedVal.toString().toLowerCase())
          )
        }
    });
    if (searchedVal.length < 1) {
        setFilter(insuredClientsList)
    }
    else {
        setFilter(filteredRows)
    }
  };
  return (
    <Page>
      <SuccessMessage successMessage={successMessage} />
      <AlertMessage errorMessage={errorMessage} />
      <section className='uploadClient'>
        <header className='insuredClientView__header'>
        <div className='insuredClientView__header__left'>
            <VerifiedUserIcon   className='insuredClientView__header__left__icon' />
            <p>Insured Persons</p>
          </div>

          <div className='insuredClientView__header__right' style={{display: "flex"}} >
            <input
              id='search-text'
              type='text'
              placeholder='Search'
              onChange={(e) => requestSearch(e.target.value)}
            />
            {/* <SearchOutlinedIcon className='insuredClientView__header__right__icon' /> */}
            <ExportCsv setErrorMessage={setErrorMessage} setSuccessMessage={setSuccessMessage}/>
            {/* <a href={`${API_KEY}/api/v1/insured_clients/export_csv`} style={{textDecoration: "none"}} target="_blank"><Button size='small' variant='outlined' style={{marginLeft: "7px"}} color='error' >Export CSV</Button></a> */}
            {/* <a href={`${API_KEY}/sample/sample_template.csv`} target='_blank'> download template</a> */}
          </div>
        </header>
        <br />
        <div className='dashboard__container__content__insuredClient__details'>
          <table className='dashboard__container__content__insuredClient__details__table__page__view'>
            <thead>
            <tr>
                <th>Company name</th>
                <th>Group policy ref number</th>
                <th>Policy year</th>
                <th>Risk start date</th>
                <th>Risk expiry date</th>
                <th>Insurance plan</th>
                <th>Member first name</th>
                <th>Member last name</th>
                <th>Member relation</th>
                <th>Member PESEL</th>
                <th>Member e-mail</th>
                <th>Cover type</th>
                <th>Mode of participation</th>
                <th>Mandated broker</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
            {insuredClientsList.length > 0 ? filter?.map((row, index) => (
              <>  
                <tr key={index} className='main_client_row'>
                  <td>{row?.company_name}</td>
                  <td></td>
                  <td>YEAR 1</td>
                  <td>{row?.risk_inception_date}</td>
                  <td>{row?.risk_expiry_date}</td>
                  <td>{row?.insurance_plan}</td>
                  <td>{row?.member_first_name}</td>
                  <td>{row?.member_last_name}</td>
                  <td>{row?.member_relation}</td>
                  <td>{row?.member_pesel}</td>
                  <td>{row?.member_email}</td>
                  <td>{row?.family_scope}</td>
                  <td>{row?.participation_mode === "voluntary" ? "VOL ": "MAN"}</td>
                  <td>{row?.mandated_broker}</td>
                  <td><Link to={`/admin/InsuredMemberDetail/${row?.id}/` } >View details</Link></td>
                </tr>

                {row?.co_insured_members !== null && (
                <>
                  {row?.co_insured_members.map((item) =>
                  <tr>
                    <td>{row?.company_name}</td>
                    <td></td>
                    <td>YEAR 1</td>
                    <td>{row?.risk_inception_date}</td>
                    <td>{row?.risk_expiry_date}</td>
                    <td>{row?.insurance_plan}</td>
                    <td>{item?.first_name}</td>
                    <td>{item?.last_name}</td>
                    <td>{item?.relation === "Spouse/Partner" ? "SP" : "CH"}</td>
                    <td>{item?.pesel_number}</td>
                    <td>{item?.email}</td>
                    <td></td>
                    <td></td>
                    <td>{row?.mandated_broker}</td>
                    <td><Link to={`/admin/CoInsuredMemberDetail/${item?.id}/` } >View details</Link></td>
                  </tr>
                )} 
                </>
              )}
            </>
              ))
                :
                <div>
                  <p>No records.</p>
                </div>}
            </tbody>
          </table>
        </div>

      </section>
    </Page>
  )
}

export default AdminFileApproval
