import { Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../../config/helpers/i18n";
import { useNavigate } from "react-router-dom";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";
import useClientInsurance from "../../../../hooks/useClientInsurance";
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import { Link } from "react-router-dom";

const GroupPolicyInfo = ({ user }) => {
  const currentUrl = window.location.href;
  const lang = currentUrl.split("/").pop();
  const { t } = useTranslation();

  useEffect(() => {
    const currentUrl = window.location.href;
    let lang = currentUrl.split("/").pop();
    lang && i18n.changeLanguage(lang === "pl" ? lang : "en");
  }, []);
  const navigate = useNavigate();

  const { getAllClientInsurance } = useClientInsurance();
  const [errorMessage, setErrorMessage] = useState(null);
  const [policyInformation, setPolicyInformation] = useState();
  const [successMessage, setSuccessMessage] = useState();

  const getPolicyInfo = async () => {
    ;
    const response = await getAllClientInsurance();
    if (response?.status < 300) {
      setPolicyInformation(response?.client_infos);
    } else if (response.status > 300) {
      setErrorMessage(response.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
    }
  };

  useEffect(() => {
    getPolicyInfo();
  }, []);

  return (
    <section className="dashboard__container__content__groupPolicyInfo">
      <header className="dashboard__container__content__groupPolicyInfo__header">

          {user?.data?.role === "member" ?
            <div className="dashboard__container__content__groupPolicyInfo__header__iconBox">
             <GppGoodOutlinedIcon lassName="dashboard__container__content__groupPolicyInfo__header__iconBox__icon" />
             <p>{t("Policyinformation.Pi_title")}</p>
            </div>
          :
            <div className="dashboard__container__content__groupPolicyInfo__header__iconBox">
              <BusinessCenterOutlinedIcon lassName="dashboard__container__content__groupPolicyInfo__header__iconBox__icon" />
              <p>{t("Policyinformation.pi_maintitle")}</p>
            </div>
          }
        
        {/*
        <Button size="small" onClick={() => navigate(`/insuredclient/group/${lang === "pl" ? lang : "en"}`)}>
          {t("Pannel_Dashboard_insuredperson.View")}
        </Button>
        */}
      </header>

      <div className="dashboard__container__content__insuredClient__details">
        <table className="dashboard__container__content__insuredClient__details__table">
          <thead>
            <tr>
              {user?.data?.role === "broker" ?
                <th>{t("Insured_clients.Companyname")}</th>
              :
                <th>{t("Policyinformation.Typeofinsurance")}</th>
              }
              <th>{t("Policyinformation.PolicyYear")}</th>
              {user?.data?.role === "broker" ?
                <th>{t("Policyinformation.noperson")}</th>
              :
                user?.data?.role === "member"  ? <th>{t("Policyinformation.Insuranceplan")}</th> : <th>{t("Policyinformation.noperson")}</th>
              }
              <th>{t("Policyinformation.Riskinceptiondate")}</th>
              <th>{t("Policyinformation.action")}</th>
            </tr>
          </thead>
          <tbody>{
                    policyInformation ? policyInformation.map((row, index) => (

                      <tr key={index} style={{ textAlign:"center", border:"none"}}>
                        {user?.data?.role === "broker" ?
                          <td style={{ textAlign:"center", border:"none"}}>{row?.corporate_client_name}</td>
                        :
                        <td>Cavitas dental insurance</td>
                        }
                        <td style={{ textAlign:"center", border:"none"}}>Year 1</td>
                        <td style={{ textAlign:"center", border:"none"}}>Pending</td>
                        <td style={{ textAlign:"center", border:"none"}}>{row?.inception_date}</td>
                        <td style={{ textAlign:"center", border:"none"}}>
                          {user?.data?.role === "member" ?
                            <Link to={`/insuredclient/group/${row?.id}/${lang === "pl" ? "pl" : "en"}` } >{t("Pannel_Dashboard_insuredperson.Open")}</Link>
                          :
                          <Link to={`/insuredclient/group/${row?.id}/${lang === "pl" ? "pl" : "en"}` } >{t("Pannel_Dashboard_insuredperson.Opengroup")}</Link>
                          }
                        </td>
                      </tr>
                    ))
                    :
                    <tr style={{ textAlign:"center", border:"none"}}>
                      <td style={{ textAlign:"center", border:"none"}} colSpan={4}>No Data To Display!</td>
                    </tr>
                  }
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default GroupPolicyInfo;

